import { useMemo } from 'react'
import { STARS } from '../constants/hotelRoomCodes'

const priorityValue = (hotel) => {
  return (hotel.priority || 100) * 100000 + hotel.distance
}

const NO_ORDER_PENALTY = 10000000
const ORDER_PENALTY = 10000

/**
 * Returns a value for a hotel suitable for ascending sorting
 *
 * @param {*} hotel
 * @returns integer value for this hotel
 */
const dealValue = (hotel) => {
  let corpOrder = Number.isInteger(hotel.sortCorporateCodeOrder)
    ? hotel.sortCorporateCodeOrder
    : 100

  return (
    hotel.distance +
    (hotel.isNegotiatedFare || hotel.isDealFare
      ? ORDER_PENALTY * corpOrder
      : NO_ORDER_PENALTY)
  )
}

export const sortHotels = (unsorted_list, sort) =>
  unsorted_list.sort((first, second) => {
    switch (sort.type) {
      case 'deal':
        return dealValue(first) - dealValue(second)
      case 'eco-label':
        const aVal = first.environmentallyFriendly ? 0 : 1
        const bVal = second.environmentallyFriendly ? 0 : 1
        if (aVal === bVal) {
          return first.distance - second.distance
        }
        return aVal - bVal
      case 'closest':
        return first.distance - second.distance
      case 'cheapest':
        return first.pricePerNight - second.pricePerNight
      case 'stars':
        const firstStars = first.CachedData.LOCALRATING
          ? parseInt(first.CachedData.LOCALRATING)
          : STARS[first.CachedData.SELFRATING]
        const secondStars = second.CachedData.LOCALRATING
          ? parseInt(second.CachedData.LOCALRATING)
          : STARS[second.CachedData.SELFRATING]
        if (secondStars - firstStars !== 0) {
          return secondStars - firstStars
        } else {
          return first.pricePerNight - second.pricePerNight
        }
      case 'agency':
        return priorityValue(first) - priorityValue(second)
      case 'avropa':
        if (!first.avropaData || !second.avropaData) return 0
        if (first.avropaData['Rank'] != second.avropaData['Rank']) {
          return first.avropaData['Rank'] > second.avropaData['Rank'] ? 1 : -1
        } else if (
          first.avropaData['Kategori'] != second.avropaData['Kategori']
        ) {
          return first.avropaData['Kategori'] > second.avropaData['Kategori']
            ? -1
            : 1
        } else {
          return first.pricePerNight - second.pricePerNight
        }

      default:
        return first.distance - second.distance
    }
  })

const useHotelSort = (search, sort) =>
  useMemo(() => sortHotels(search, sort), [search, sort])

export default useHotelSort
