import React, { memo, useEffect } from 'react'
import { Box } from '@material-ui/core'
import LoginBlock from '../../components/reusable/loginBlock'
import { useSelector } from 'react-redux'
import { initiateOidcLogin } from '../../utils/oidc/oidcHandler'

const Login = () => {
  const { onlyOidc } = useSelector((state) => state.other)

  useEffect(() => {
    if (onlyOidc) {
      initiateOidcLogin('login', '/')
    }
  }, [])

  if (onlyOidc) {
    return null
  }

  return (
    <Box>
      <LoginBlock />
    </Box>
  )
}

export default memo(Login)
