import React, { memo, Fragment } from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
  openCancelBookingModalAction,
  openSimpleInfoModal,
} from '../../../../store/modals/modalAction'
import { setBookingNumberAction } from '../../../../store/myTrips/myTripsAction'
import Box from '@material-ui/core/Box'
import {
  canCancelBooking,
  getTripServiceTexts,
} from '../../../../utils/general'
import moment from 'moment'
import { Add, Cached, Clear } from '@material-ui/icons'
import CustomIconButton from '../../../../components/reusable/customIconButton'
import { Button } from '@material-ui/core'
import defaultTimelineStyles from '../../../../components/myTrip/defaultTimeline.styles'
import FetchMyTrips from '../../../../repositories/my-trips'
import { showSnackbarAction } from '../../../../store/snackbars/snackbarAction'

const useStyles = makeStyles((theme) => ({
  root: { width: '100%' },
  justiceBetween: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  align: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
  cancelledBlock: {
    fontSize: '14px',
    textTransform: 'uppercase',
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightSemiBold,
    padding: '10px 0',
    width: '100%',
    textAlign: 'center',
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
  text: {
    fontSize: '14px',
    marginBottom: '8px',
    color: '#757575',
  },
  bordered: {
    paddingTop: theme.spacing(3),
    marginTop: theme.spacing(3),
    borderTop: `1px solid ${theme.palette.divider}`,
    lineHeight: 'normal',
    '&:first-child': {
      marginTop: '0px',
    },
  },
  btnRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: '0px',
  },
  btn: {
    fontSize: '14px',
    letterSpacing: '1.1px',
    flex: 1,
    fontWeight: '700',
    borderRadius: theme.spacing(3),
    lineHeight: '1.3',
    padding: theme.spacing(1, 2),
    marginRight: '15px',

    '&:last-child': {
      marginRight: 0,
    },
  },
  extBtn: {
    boxShadow: 'none',
    marginBottom: '15px',

    '&:hover': {
      boxShadow: 'none',
    },
  },
  btnContainer: {
    textAlign: 'center',
  },
  showTicketBtn: {
    borderRadius: theme.spacing(3),
    backgroundColor: theme.palette.primary.medium,
    color: theme.palette.primary.dark,
    marginRight: '10px',

    '&:last-child': {
      marginRight: 0,
    },
  },
}))

const MyTripFooter = ({
  showOnly,
  model,
  enableCancel = true,
  enableExtend = false,
  onExtend,
  onSendCancellationEmail,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const defaultClasses = defaultTimelineStyles()
  const dispatch = useDispatch()
  const { users } = useSelector((state) => state.users)
  const { user, agencyTitle } = useSelector((state) => state.auth)
  let bookedBy = users.find(({ uniqueId }) => uniqueId === model.userId)
  let cancelledBy = model?.cancelledBy || null
  let isShowExtend =
    (!showOnly || showOnly.includes('buttons')) &&
    enableExtend &&
    model.isUpcoming &&
    !model.cancelled

  const isOffline = model.parsed === true

  if (model?.agentData?.name) {
    bookedBy = model?.agentData?.name
    if (agencyTitle) {
      bookedBy += ` (${agencyTitle})`
    }
  }

  if (isOffline) {
    bookedBy = t('booked by agency')
  }

  const openCancelModalAndSetActiveBooking = () => {
    const { canCancel, reason } = canCancelBooking(user, model)
    const infoTexts = getTripServiceTexts(model, true)
    if (infoTexts.length)
      infoTexts.unshift({
        text: `${t('rules')}:`,
        styles: { fontWeight: 'bold' },
      })

    if (!canCancel) {
      const textKey = reason === 'rbk-nref' ? 'rbk-nref' : 'forbidden'
      dispatch(
        openSimpleInfoModal({
          title: t('cancel prohibit title'),
          text: t(`cancel ${textKey} note`, {
            phone: user?.supportTelephone || '',
          }),
          buttonText: t('ok'),
          infoTexts: infoTexts,
        })
      )
      return true
    }

    const payload = { pnr: model.pnr }
    dispatch(setBookingNumberAction(payload))
    dispatch(openCancelBookingModalAction({ infoTexts }))
  }

  const handleSendConfirmationEmail = async () => {
    try {
      const tripsRepository = new FetchMyTrips()
      await tripsRepository.requestResendConfirmationEmail(model.pnr)

      dispatch(
        showSnackbarAction('success', t('confirmation sent'), {
          x: 'right',
          y: 'top',
        })
      )
    } catch (ex) {
      dispatch(
        showSnackbarAction('error', t('confirmation failed'), {
          x: 'right',
          y: 'top',
        })
      )
    }
  }

  const rebookModal = () => {
    const infoTexts = getTripServiceTexts(model, true)
    if (infoTexts.length)
      infoTexts.unshift({
        text: `${t('rules')}:`,
        styles: { fontWeight: 'bold' },
      })

    dispatch(
      openSimpleInfoModal({
        title: t('rebook prohibit title'),
        text: t('cancel rbk-nref note', {
          phone: user?.supportTelephone || '',
        }),
        buttonText: t('ok'),
        infoTexts: infoTexts,
      })
    )
  }

  return (
    <Grid container>
      {(!showOnly || showOnly.includes('bookData')) && (
        <Box width="100%">
          <Box className={`${classes.bordered} ${classes.text}`} item xs={12} />

          <Grid className={classes.text} item xs={12}>
            <span>{t('book date')} </span>
            <span>
              {moment(model.created).format('YYYY-MM-DD')} {t('at')}{' '}
              {moment(model.created).format('HH:mm')}
            </span>
          </Grid>

          {model.costfields?.map((field) => (
            <Grid className={classes.text} item xs={12}>
              {!!field?.label && (
                <span className={classes.weight}>{field.label}: </span>
              )}
              <span>{field.value}</span>
            </Grid>
          ))}

          {bookedBy && (
            <Grid className={classes.text} item xs={12}>
              <span>{t('booked by')} </span>
              <span>
                {typeof bookedBy === 'object' &&
                  `${bookedBy.firstName} ${bookedBy.lastName}`}
                {typeof bookedBy === 'string' && bookedBy}
              </span>
            </Grid>
          )}

          {model.cancelled && (
            <Grid className={classes.text} item xs={12}>
              <span>{t('cancelled by')} </span>
              <span>
                {!!cancelledBy?.name && cancelledBy.name}
                {cancelledBy?.authType === 'agent' && ` (${agencyTitle})`}
                {!cancelledBy && ` ${agencyTitle}`}
              </span>
            </Grid>
          )}

          {model.confirmationEmail && (
            <Grid className={classes.text} item xs={12}>
              <Button
                disableElevation
                variant="outlined"
                className={defaultClasses.smallBtn}
                onClick={handleSendConfirmationEmail}
              >
                {t('send confirmation email')}
              </Button>
            </Grid>
          )}
        </Box>
      )}

      {(!showOnly || showOnly.includes('buttons')) && (
        <Grid
          className={`${classes.btnRow} ${classes.bordered}`}
          container
          flex
          xs={12}
          // style={{
          //   left: '0px',
          //   bottom: '0px',
          //   position: 'sticky',
          //   marginTop: '24px',
          //   textAlign: 'center',
          //   paddingLeft: '24px',
          //   paddingRight: '24px',
          //   backgroundColor: '#fff',
          // }}
        >
          {!!isShowExtend && (
            <Grid item xs className={classes.btnContainer}>
              <CustomIconButton
                onClick={onExtend}
                color="primary"
                label={t('add to trip')}
                icon={<Add />}
              />
            </Grid>
          )}

          {model.isUpcoming && !model.cancelled && (
            <Fragment>
              {user?.showRebookButton && (
                <Grid item xs className={classes.btnContainer}>
                  <CustomIconButton
                    onClick={rebookModal}
                    label={t('rebook')}
                    icon={<Cached />}
                    color="red"
                  />
                </Grid>
              )}

              {enableCancel && (
                <Grid item xs className={classes.btnContainer}>
                  <CustomIconButton
                    onClick={openCancelModalAndSetActiveBooking}
                    label={t('cancel booking full')}
                    disableRipple
                    color="red"
                    icon={<Clear />}
                  />
                </Grid>
              )}
            </Fragment>
          )}
        </Grid>
      )}
    </Grid>
  )
}

export default memo(MyTripFooter)
