import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'
import moment from 'moment'
import capitalize from '../../utils/capitalizeFirstLetter'
import {
  canCancelBooking,
  prepareCancellationProhibitedModal,
  prepareTrainSeats,
} from '../../utils/general'
import Box from '@material-ui/core/Box'
import defaultStyles from './defaultTimeline.styles'
import { TRAIN_COMPANIES } from '../../constants/trainCompanies'
import { Divider, Grid, Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import {
  openSimpleInfoModal,
  setBookingPartCancelModalData,
} from '../../store/modals/modalAction'
import CustomIconButton from '../reusable/customIconButton'
import { ArrowForward, Clear } from '@material-ui/icons'
import GetCompanyIcon from '../reusable/getCompanyIcon'
import TripHeader from './tripHeader'
import { createPortal } from 'react-dom'

const flexTexts = {
  '002': 'refundable',
  '003': 'rebookable',
  '004': 'nonRebookable',
  '005': 'refundable',
  '006': 'refundable',
  '007': 'refundable',
}

const getDuration = (startDate, endDate) => {
  const sMoment = moment(startDate)
  const eMoment = moment(endDate)
  if (!sMoment.isValid() || !eMoment.isValid()) return ''
  const diffHrs = eMoment.diff(sMoment, 'hours')
  const diffMin = eMoment.diff(sMoment, 'minutes') - diffHrs * 60

  return `${diffHrs > 0 ? diffHrs + 'h' : ''} ${
    diffMin > 0 ? diffMin + 'm' : ''
  }`
}

const TrainTimeline = ({
  model,
  enableCancel = true,
  outboundPrice = null,
  returnPrice = null,
  onClose,
  mainRef,
}) => {
  const { rails, accommodations, railRefs } = model
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const classes = defaultStyles()
  const seats = prepareTrainSeats(accommodations)
  let itmKey = 1
  let firstDeparture = null
  const { user, currency } = useSelector((state) => state.auth)
  const { outbound: outboundTrip, return: returnTrip } = rails

  const onCancel = async () => {
    const { canCancel, reason } = canCancelBooking(user, model, ['rail'])
    if (!canCancel) {
      const modalData = prepareCancellationProhibitedModal(reason, model, user)
      return dispatch(openSimpleInfoModal(modalData))
    }

    dispatch(
      setBookingPartCancelModalData({
        type: 'train',
        passengers: model?.passengers,
        pnr: model?.pnr,
        model: model,
        item: { ...rails },
      })
    )
  }

  const isSeparateCancelDisabled =
    model.netsPaymentId !== null || model.paiwiseCheckoutId

  const isCancelled =
    !!model &&
    (outboundTrip?.cancelled || returnTrip?.cancelled || model?.cancelled)
  const getCancelButton = () => {
    if (isSeparateCancelDisabled) return null
    if (!model?.pnr) return null
    if (isCancelled) {
      return <Box className={classes.cancelledText}>{t('cancelled')}</Box>
    }
    if (!model?.isUpcoming || !enableCancel) return null
    return (
      <CustomIconButton
        label={`${t('cancel booking')} ${t('train').toLowerCase()}`}
        color="red"
        icon={<Clear />}
        onClick={(e) => {
          e.stopPropagation()
          onCancel()
        }}
      />
    )
  }

  const renderSolution = (solution) => {
    const durationText = getDuration(
      solution.railstart.dateTime,
      solution.railend.dateTime
    )
    const stops = solution.segments.length - 1
    const stopText =
      stops > 0
        ? `${stops} ${t(stops > 1 ? 'stops' : 'stop')}`
        : t('filter directly')

    const from = capitalize(solution.railstart.locationName)
    const to = capitalize(solution.railend.locationName)

    return (
      <Box className={classes.card}>
        <Box mt={1} mb={1}>
          <Grid container spacing={2}>
            <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
              <GetCompanyIcon
                height={20}
                width={20}
                company={solution.serverProviderCode}
              />
            </Grid>
            <Grid item xs={10}>
              <Box class={classes.subTitle}>
                {from}{' '}
                <ArrowForward
                  style={{
                    height: '16px',
                    display: 'inline',
                    position: 'relative',
                    top: '2px',
                  }}
                />{' '}
                {to}
              </Box>

              <Box>
                {capitalize(
                  moment(solution.railstart.dateTime).format('ddd DD MMM')
                )}
                <Box className={classes.dot} />
                {durationText}
                <span style={{ color: '#666', marginLeft: '10px' }}>
                  ({stopText})
                </span>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box mt={3} mb={3}>
          <Divider />
        </Box>

        {renderSegments(solution.segments)}
      </Box>
    )
  }

  const trainModels = {
    X2: 'X2000',
    RE: 'Regional',
  }

  const renderSegments = (segments) =>
    segments.map((line, i, all) => {
      const flexibility = line.flexibility || ''
      const providerCode = line?.railserviceProvider?.Code || ''
      const company = TRAIN_COMPANIES[providerCode] || null
      const underText = `${!!company ? company + ', ' : ''}${
        providerCode || ''
      } ${line.railidentifier || ''}`
      if (!firstDeparture) firstDeparture = new moment(line.railstart?.dateTime)

      let trainType =
        trainModels[line.railvehicle.Code] || line.railvehicle.Code

      const seatsToShow = []

      if (
        seats[line.railidentifier] &&
        typeof seats[line.railidentifier] === 'object'
      ) {
        for (let coach in seats[line.railidentifier]) {
          if (!seats[line.railidentifier][coach]?.length) continue
          const seatNums = seats[line.railidentifier][coach].join(', ')
          seatsToShow.push(
            <span key={`itm-${itmKey++}`}>
              <span key={`itm-${itmKey++}`}>
                {t('vagn')} {coach},{' '}
              </span>
              <span key={`itm-${itmKey++}`}>
                {t('seats')} {seatNums}
              </span>
              <span key={`itm-${itmKey++}`} style={{ marginLeft: '1.5ch' }}>
                ( {providerCode} {line.railidentifier} )
              </span>
            </span>
          )
        }
      }
      const startTime = line.railstart?.dateTime
      const startCity = line.railstart?.locationName
      const endTime = line.railend?.dateTime
      const endDestination = line.railend?.locationName
      // If MiddleStation it will get next index
      const nextRide = i < segments.length - 1 && all[i + 1]

      const duration = getDuration(endTime, nextRide?.railstart?.dateTime)
      const segmentDuration = getDuration(startTime, endTime)

      return (
        <Box key={`itm-${itmKey++}`} mt={1} mb={1}>
          <Box>
            <span className={classes.weight}>
              <Moment format="HH:mm">{startTime}</Moment>&nbsp;&mdash;&nbsp;
              <Moment format="HH:mm">{endTime}</Moment>
            </span>
            &nbsp; ({segmentDuration})
          </Box>
          <Box mt={1}>
            {capitalize(startCity)}{' '}
            <ArrowForward
              style={{
                height: '16px',
                display: 'inline',
                position: 'relative',
                top: '2px',
              }}
            />{' '}
            {capitalize(endDestination)}
          </Box>

          <Box className={classes.details} mt={3}>
            <Box>{underText || ''}</Box>

            {seatsToShow.length > 0 && (
              <Box>
                {seatsToShow.map((s) => (
                  <Box key={`itm-${itmKey++}`}>{s}</Box>
                ))}
              </Box>
            )}

            {!!flexTexts?.[flexibility] && (
              <Box key={`itm-${itmKey++}`}>{t(flexTexts[flexibility])}</Box>
            )}

            <Box>{trainType}</Box>

            {i < segments.length - 1 && (
              <>
                <Box mt={2}>
                  <Divider />
                </Box>
                <Box mt={2} mb={2} style={{ color: 'black' }}>
                  <span>
                    <span className={classes.weight}>{duration}</span>{' '}
                    {duration}
                  </span>{' '}
                  <span>{t('stop')}</span>
                </Box>
                <Box mb={3}>
                  <Divider />
                </Box>
              </>
            )}
          </Box>
        </Box>
      )
    })

  const price = outboundPrice + returnPrice

  const tripText = returnTrip ? t('trip with return') : t('detail one way')
  const passengerText =
    model.passengers.length > 1 ? t('traveler') : t('travelers')

  const railstart =
    outboundTrip?.railstart?.locationName || returnTrip?.railstart?.locationName
  const railend =
    outboundTrip?.railend?.locationName || returnTrip?.railend?.locationName

  return (
    <>
      <TripHeader type="train" onClose={onClose} />

      <Box className={classes.titleSection}>
        <Typography className={classes.title}>
          {railstart} {t('to').toLowerCase()} {railend}
        </Typography>
        <Typography className={classes.subtitle}>
          {tripText}, {model.passengers.length} {passengerText}
        </Typography>
      </Box>

      {outboundTrip?.segments && renderSolution(outboundTrip)}

      {returnTrip?.segments && renderSolution(returnTrip)}

      <Box className={classes.card}>
        <Typography className={classes.subTitle}>
          {t('other details')}
        </Typography>
        <Box className={classes.details}>
          {railRefs?.length > 0 && (
            <Box>
              {t('book data rail ref')} {railRefs.join(', ')}
            </Box>
          )}
          <Box>
            {t('emission')} {rails.co2} {t('kilo')}
          </Box>
          <Box>
            {t('price')} {price} {currency}
          </Box>
        </Box>
      </Box>

      <Box style={{ marginBottom: '100px' }} />

      {createPortal(
        <Box className={classes.footer}>
          <Divider mb={2} />
          {getCancelButton()}
        </Box>,
        mainRef.current
      )}
    </>
  )
}

export default memo(TrainTimeline)
