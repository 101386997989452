import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Box, Button, Grid, useMediaQuery } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import HotelSkeletons from '../../components/reusable/skeletons/hotelSkeletons'
import CarFilterDrawer from '../cars/carFilterDrawer'
import { useTranslation } from 'react-i18next'
import SortBy from '../../components/reusable/sortBy'
import useSort from '../../hooks/useSortHook'
import SingleCarsDataBlock from './singleCarsDataBlock'
import { setCarFilters, setSelectedCar } from '../../store/cars/carAction'
import { applyCarFilters } from '../../utils/carHelpers'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import { useCardRef } from '../../hooks/useCardRef'
import SearchLocationToggle from '../../components/hotels/searchLocationToggle'

const useStyles = makeStyles((theme) => ({
  resultsOuter: {
    padding: '30px 18px',
    borderRadius: '12px',
    backgroundColor: theme.palette.dimWhite,
    minHeight: '700px',
    position: 'absolute',
    left: '20px',
    [theme.breakpoints.down('sm')]: {
      left: '0',
    },
  },
  filterRow: {
    display: 'flex',
  },
  filtersBtn: {
    backgroundColor: 'white',
    padding: '6px 13px',
    marginRight: '8px',
  },
  countHeader: {
    fontSize: '36px',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.black,
  },
  bold: {
    fontWeight: 'bold',
  },
  seeAllCars: {
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightSemiBold,
    background: 'white',
    marginLeft: '15px',

    [theme.breakpoints.down('sm')]: {
      marginLeft: '0',
      marginRight: '5px',
    },
  },
  preFilterText: {
    flex: 1,
    fontSize: '16px',
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.common.black,
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      marginBottom: '24px',
    },
  },
  filterBlock: {
    display: 'flex',
    marginTop: '8px',
    marginBottom: '23px',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  singleCar: {
    borderRadius: '8px',
    boxShadow: '0 1px 3px -1px rgba(0, 0, 0, 0.2)',
    backgroundColor: 'white',
    marginBottom: '20px',
    padding: '0 24px',
    paddingLeft: 0,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: '0 15px',
      paddingTop: '15px',
    },
  },
  disableFoglight: {
    position: 'absolute',
    borderRadius: '8px',
    backgroundColor: '#00000042',
    textShadow: '0px 0px 9px #00000036',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '25px',
  },

  seeAllButton: {
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    textTransform: 'unset',
    border: 'unset',
    marginTop: '20px',
    paddingLeft: '40px',
    paddingRight: '40px',
  },

  nothingFoundContainer: {
    paddingTop: '60px',
    paddingBottom: '60px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const CarsSearchContent = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const {
    cars = {},
    isLoading,
    filters,
    selectedCar,
  } = useSelector((state) => state.cars)
  const [filtersOpen, setFiltersOpen] = useState(false)
  const [filtering, setFiltering] = useState({
    loading: false,
    cars: [],
  })
  const { user } = useSelector((state) => state.auth)
  const [firstMount, setFirstMount] = useState(true)
  const [selected, setSelected] = useState(null)
  const { t } = useTranslation()
  const history = useHistory()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const inProcess = useMemo(
    () => !!isLoading || !!filtering.loading,
    [isLoading, filtering]
  )
  const fLoc = useMemo(() => {
    const locObj = cars?.locations?.find(
      (l) => !!l?.uniqueKey && l?.uniqueKey === filters?.locationKey
    )
    if (!locObj?.addressLine) return null
    return (
      _.startCase(_.toLower(locObj.companyName)) +
      ', ' +
      _.startCase(_.toLower(locObj.addressLine))
    )
  }, [cars, filters])

  const clearLocationFilter = useCallback(() => {
    dispatch(setCarFilters({ locationKey: false }))
  }, [dispatch])

  const getTopRow = () => {
    if (!fLoc) return t('select car of your choice')
    return (
      <span>
        {t('only cars from')} <span className={classes.bold}>{fLoc}</span>
      </span>
    )
  }

  const btnSeeAllCars = () => {
    if (!fLoc) return null
    return (
      <Button
        variant="outlined"
        color="primary"
        onClick={clearLocationFilter}
        className={classes.seeAllCars}
      >
        {t(isMobile ? 'all cars' : 'see all cars', {
          count: cars?.rates?.length || 0,
        })}
      </Button>
    )
  }
  const sortTypes = useMemo(() => {
    let types = [
      { type: 'closest', label: t('closest') },
      { type: 'cheapest', label: t('sorted cheapest') },
      { type: 'corporate-fare', label: t('sorted deal') },
    ]
    if (user?.sortTypes?.carSortType === 'car-company') {
      types.push({ type: 'car-company', label: t('company') })
    }
    return types
  }, [t, user])
  const { sort, anchorEl, handleClose, handleClick } = useSort(
    sortTypes,
    null,
    null,
    'cars'
  )

  const filterResults = useCallback(
    async (rqRates) => {
      setFiltering({
        loading: true,
        cars: [],
      })

      const res = applyCarFilters(
        rqRates,
        filters,
        sort?.type || null,
        user?.carVendorOrder
      )
      setFiltering({
        loading: false,
        cars: res,
      })
    },
    [filters, sort]
  )

  const selectCar = useCallback(
    (car) => {
      if (!car) return
      dispatch(setSelectedCar(car))
      history.push('/cars/details')
    },
    [dispatch, history]
  )

  useEffect(() => {
    if (!isLoading) {
      // apply filters
      filterResults(cars?.rates)
    } else {
      setFiltering({
        loading: false,
        cars: [],
      })
    }
  }, [cars, filterResults, isLoading])

  const cardRef = useCallback(
    (node) =>
      useCardRef(
        node,
        selectedCar?.rateId,
        setSelected,
        selected,
        firstMount,
        isMobile,
        false
      ),
    [selectedCar, firstMount, selected, isMobile]
  )

  const selectRef = (car) => {
    if (car.rateId === selectedCar?.rateId) {
      return cardRef
    } else {
      return null
    }
  }

  useEffect(() => {
    setFirstMount(false)
  }, [])

  let carsCount = 0

  const renderCars = (filtering?.cars || []).map((c, i) => {
    const missingData = !c?.vehicle?.model || !c?.estimatedTotal
    const key = `car-rate-${c.rateId}`
    carsCount++
    return (
      <Grid
        ref={selectRef(c)}
        item
        xs
        key={key}
        onClick={() => selectCar(!missingData ? c : null)}
        className={classes.singleCar}
      >
        <Box
          style={{ minHeight: isMobile ? '590px' : '290px', display: 'flex' }}
        >
          <SingleCarsDataBlock
            key={'car-rate-info-component' + c.rateId}
            car={c}
          />
        </Box>
        {!!missingData && (
          <Box className={classes.disableFoglight}>
            {t('missing required data')}
          </Box>
        )}
      </Grid>
    )
  })

  // Is eco filter enabled?
  const isEcoEnabled =
    filters && filters.fuel?.length == 1 && filters.fuel[0] == 'green'

  // Show helpful message about disabling eco filter if we have no results due to filter
  const showEcoMessage =
    isEcoEnabled && filtering?.cars.length == 0 && cars.rates?.length > 0

  const toggleEcoCars = () => {
    if (isEcoEnabled) {
      dispatch(setCarFilters({ fuel: [] }))
    } else {
      dispatch(setCarFilters({ fuel: ['green'] }))
    }
  }

  return (
    <Box key="cars-result-outer" className={classes.resultsOuter}>
      {inProcess ? (
        <HotelSkeletons />
      ) : (
        <Fragment>
          <Box key="cars-result-counter" className={classes.countHeader}>
            <span>
              {carsCount} {t('cars available')}
            </span>
            {!isMobile && btnSeeAllCars()}
          </Box>
          <Box key="cars-result-filter-row" className={classes.filterBlock}>
            <Box
              key="cars-result-filter-row-text"
              className={classes.preFilterText}
            >
              {getTopRow()}
            </Box>
            <Box
              className={classes.filterRow}
              key="cars-result-filter-row-btns"
              display="inline-block"
            >
              <Box mr={1}>
                <SearchLocationToggle
                  label1={t('green cars')}
                  label2={t('all cars')}
                  onChange={toggleEcoCars}
                  isToggled={!isEcoEnabled}
                />
              </Box>

              {!!isMobile && btnSeeAllCars()}
              <Button
                className={classes.filtersBtn}
                key="cars-result-filter-row-fbtn"
                onClick={() => setFiltersOpen(true)}
              >
                {t('filters')}
              </Button>
              <SortBy
                key="cars-result-filter-row-sbtn"
                sort={sort}
                sortTypes={sortTypes}
                anchorEl={anchorEl}
                handleClick={handleClick}
                handleClose={handleClose}
              />
            </Box>
          </Box>
          {showEcoMessage && (
            <Box className={classes.nothingFoundContainer}>
              <div>{t('no green cars found')}</div>

              <Button
                onClick={toggleEcoCars}
                className={classes.seeAllButton}
                style={{ backgroundColor: theme.palette.primary.main }}
              >
                {t('see all cars', { count: cars.rates?.length })}
              </Button>
            </Box>
          )}
          <Grid
            container
            style={{ flexWrap: 'nowrap' }}
            direction="column"
            spacing={2}
          >
            {renderCars}
          </Grid>
        </Fragment>
      )}
      <CarFilterDrawer
        open={filtersOpen}
        onClose={() => setFiltersOpen(false)}
      />
    </Box>
  )
}

export default CarsSearchContent
