import agent from './agent'

let outboundPostLimitingTimer = undefined
let outboundPostCounter = 0

// POST fetchest outbound Trains
export const fetchOutboundTrains = async (payload) => {
  const url = '/v2/rail/outbound'
  const config = {}

  outboundPostCounter++

  outboundPostLimitingTimer = setTimeout(() => {
    outboundPostCounter = 0
    clearTimeout(outboundPostLimitingTimer)
    outboundPostLimitingTimer = undefined
  }, 500)

  if (outboundPostCounter === 2) {
    config.headers = {
      'X-Suspicious-Request': 'true'
    }
  }

  if (outboundPostCounter > 2) {
    console.warn('Too many requests')
    return undefined
  }

  try {
    const { data } = await agent.post(url, payload, config)
    return data
  } catch (error) {
    console.log(error)
  }
}

// POST fetches Returning trains
export const fetchReturningTrains = async (payload) => {
  const url = '/v2/rail/return'
  try {
    const { data } = await agent.post(url, payload)
    return data
  } catch (error) {
    console.log(error)
  }
}

// GET fetches cities
export const fetchRailCity = async (string) => {
  if (!string) string = ''

  const url = `/v2/rail/city/${string}`

  try {
    const { data } = await agent.get(url)
    return data
  } catch (error) {
    console.log(error)
  }
}

/* Fetches Train City based on Code */
export const fetchTrainCityByCode = async (code) => {
  if (!code) return
  const url = `/v2/rail/city-by-code/${code}`
  try {
    const { data } = await agent.get(url)
    return data
  } catch (error) {
    console.log(error)
  }
}

// POST Fetches Train Accommodations
export const fetchFethTrainAccommodation = async (payload) => {
  const url = '/rail/get-accommodation'
  try {
    const { data } = await agent.post(url, payload)
    return data
  } catch (error) {
    console.log(error)
  }
}

// POST Fetches Train Accommodations
export const fetchTrainSeatMap = async (payload) => {
  const url = '/rail/get-seats'
  try {
    const { data } = await agent.post(url, payload)
    return data
  } catch (error) {
    return null
  }
}

/* GET Fetch middlestep setting */
export const fetchGetMiddleStepSettings = async () => {
  const url = '/middlestep-settings'
  const { data } = await agent.get(url)
  return data
}
